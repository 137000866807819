import React, { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Modal,
  Card,
  Table,
  Row,
  Col,
  Button,
  Tooltip,
  Input,
  Form,
  Spin,
  List,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation} from 'react-router-dom';
import {
  getSalesRequestData,
  getSalesMappedData,
  editRoyaltySalesRequestData,
  downloadPOApprovalRequestsDocument,
} from "../../../appRedux/actions";
import {
  EditOutlined,
  EyeTwoTone,
  InfoCircleTwoTone,
  CopyOutlined,
  MessageTwoTone,
  DownloadOutlined,
} from "@ant-design/icons";
import { getRole, getUserInfo, getIsApprover } from "../../../helpers";
import moment from "moment";
import { royaltyApprovalStatus } from "../../../constants";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./needs-clarification.css";
import ClarificationChat from "./clarification_chat_popup";
import classNames from "classnames";
const { TextArea } = Input;

const PartnerRoyaltySalesApproval = (props) => {
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const [isUserStaff, setIsUserStaff] = useState(false);
  const [productFilterOptions, setProductFilterOptions] = useState([]);
  const [countryFilterOptions, setCountryFilterOptions] = useState([]);
  const [partnerFilterOptions, setPartnerFilterOptions] = useState([]);
  const [isAdminApprover, setIsAdminApprover] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [chatConversation, setChatConversation] = useState([]);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [requestSatus, setRequestStatus] = useState();
  const [requestObj, setRequestObj] = useState({ page_number: 1, page_size: 10000 });
  const history = useHistory();
  const location = useLocation();
  var queryParams = new URLSearchParams(location.search);

  const columnDefs = [
    {
      headerName: "Company Name",
      field: "partner_id.company_name",
      width: 150,
    },
    {
      headerName: "Product Name",
      field: "product_id.product_name",
      width: 250,
    },
    {
      headerName: "Country Name",
      field: "country_id.country_name",
      width: 150,
    },
    { headerName: "Quarter", field: "quarter_id.quarter_name", width: 150 },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 150,
      valueFormatter: (params) => formatDecimal(params.value, 2),
    },
    {
      headerName: "Pack Size",
      field: "pack_size",
      width: 150,
      valueFormatter: (params) => formatDecimal(params.value, 2),
    },
  ];

  function formatDecimal(value, decimalPlaces) {
    return parseFloat(value).toFixed(decimalPlaces);
  }

  const handleClarificationClick = (chatMessages) => {
    setChatConversation(chatMessages);
    setIsChatModalVisible(true);
  };

  const handleRequestClick = (requestId, mappingId) => {
    dispatch(getSalesMappedData(requestId));
    setSelectedRequest(mappingId);
    setIsModalVisible(true);
  };

  const gridOptions = {
    // ag-Grid options
  };

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSendMessage = (item) => {
    if (messageInput.trim() !== "") {
      const newMessage = {
        message: messageInput.trim(),
        is_partner: isUserPartner,
      };
      const rejectFormData = new FormData();
      rejectFormData.append("status", "NEEDS CLARIFICATION");
      rejectFormData.append("message", newMessage.message);
      dispatch(
        editRoyaltySalesRequestData(
          item,
          rejectFormData,
          () => {
            dispatch(getSalesRequestData());
          },
          "Message Sent"
        )
      );
      const updatedChatConversation = [...chatConversation, newMessage];
      setMessageInput("");
      setChatConversation(updatedChatConversation);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const {
    salesrequestdata,
    salesmappeddata,
    isRoyaltySalesMappedLoaded,
    isSalesRequestDeleted,
    isRoyaltySalesLoaded,
  } = useSelector(({ royaltySales }) => royaltySales);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const navigateToAddSalesData = () => {
    const { history } = props;
    history.push("/partner/royalty-sales-approvals/raise-request");
  };

  useEffect(() => {
    setUserDetails(getUserInfo());
    setIsAdminApprover(getIsApprover());
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });
    setIsUserStaff(() => {
      return getRole() === "STAFF";
    });
    dispatch(getSalesRequestData());
  }, []);

  useEffect(() => {
    setData(salesrequestdata);
  }, [isSalesRequestDeleted, isRoyaltySalesLoaded]);

  const navigateToContent = (item) => {
    props.history.push({pathname:
      `/partner/royalty-sales-approvals/edit-request/${item.request_id}`, location: queryParams.toString()
    });
  };

  const navigateToViewContent = (item) => {
    props.history.push({pathname:
      `/partner/royalty-sales-approvals/view/${item.request_id}`,  search: queryParams.toString()
    });
  };

  useEffect(() => {
    const uniqueProductNames = [
      ...new Set(data.map(({ product_id }) => product_id?.product_name)),
    ];
    const uniqueCountryNames = [
      ...new Set(data.map(({ country_id }) => country_id?.country_name)),
    ];
    const uniquePartnerNames = [
      ...new Set(data.map(({ partner_id }) => partner_id?.company_name)),
    ];
    setProductFilterOptions(uniqueProductNames);
    setCountryFilterOptions(uniqueCountryNames);
    setPartnerFilterOptions(uniquePartnerNames);
  }, [data]);


  // Handle filter change
  const handleTableChange = (pagination, filters) => {
    var queryParams = new URLSearchParams();
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (filters[key].length > 0) {
          for (let value of filters[key]) {
            queryParams.append(key, value);
          }
        } else {
          queryParams.append(key, filters[key]);
        }
      }
    });
    if (Object.keys(filters).length > 0) {
      history.push({search: `?${queryParams.toString()}`})
    } else {
      history.push({ search: '' })
    }
  }
  const handleDownloadClick = () => {
    let paramsObject = {};

    // Map to rename keys
    const keyMap = {
      partner_id: "partner_name",
      product_id: "product",
      country_id: "country_of_sales",
      status: "status",
      mapping_id: "request_id",
    };

    // Iterate through all entries
    for (const [key, value] of queryParams.entries()) {
      const newKey = keyMap[key] || key; // Replace the key if it's in the keyMap, otherwise keep it

      // Check if the key already exists
      if (paramsObject[newKey]) {
        // If it's not an array, convert it to an array
        if (!Array.isArray(paramsObject[newKey])) {
          paramsObject[newKey] = [paramsObject[newKey]];
        }
        // Add the new value to the array
        paramsObject[newKey].push(value);
      } else {
        if (value?.length > 0) {
          // If key doesn't exist, just assign the value
          paramsObject[newKey] = [value];
        } else {
          paramsObject[newKey] = null;
        }
      }
    }
    const payload = { ...requestObj, ...paramsObject };
    dispatch(
      downloadPOApprovalRequestsDocument(
        "royalty-po-approval-requests.csv",
        payload
      )
    );
  };

  const columns = [
    ...(isUserAdmin || isUserStaff
      ? [
          {
            title: "Partner Name",
            dataIndex: ["partner_id", "company_name"],
            key: "partner_id",
            align: "center",
            width: 200,
            filters: partnerFilterOptions.map((option) => ({
              text: option,
              value: option,
            })),
            defaultFilteredValue: queryParams.get("partner_id")? queryParams.getAll("partner_id") : [],
            onFilter: (value, record) =>
              record["partner_id"]["company_name"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
          },
        ]
      : []),
    {
      title: "Product",
      dataIndex: ["product_id", "product_name"],
      key: "product_id",
      align: "center",
      width: 300,
      filters: productFilterOptions.map((option) => ({
        text: option,
        value: option,
      })),
      defaultFilteredValue: queryParams.get("product_id")? queryParams.getAll("product_id") : [],
      onFilter: (value, record) =>
        record["product_id"]["product_name"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Country Of Sale",
      dataIndex: ["country_id", "country_name"],
      key: "country_id",
      align: "center",
      width: 180,
      filters: countryFilterOptions.map((option) => ({
        text: option,
        value: option,
      })),
      defaultFilteredValue: queryParams.get("country_id")? queryParams.getAll("country_id") : [],
      onFilter: (value, record) =>
        record["country_id"]["country_name"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      align: "center",
      width: 140,
      defaultSortOrder: "desc",
      sorter: (a, b) => a["request_date"].localeCompare(b["request_date"]),
      render: (text, record) =>
        moment(record.request_date).format("DD/MM/YYYY"),
    },
    {
      title: "Approval Date",
      dataIndex: "approval_date",
      key: "approval_date",
      align: "center",
      width: 140,
      sorter: (a, b) => a["approval_date"].localeCompare(b["approval_date"]),
      render: (text, record) => {
        if (record.approval_date) {
          return moment(record.approval_date).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      title: "Expected Date Of Sales",
      dataIndex: "expected_date_of_sale",
      key: "expected_date_of_sale",
      align: "center",
      width: 140,
      sorter: (a, b) =>
        a["expected_date_of_sale"].localeCompare(b["expected_date_of_sale"]),
      render: (text, record) =>
        moment(record.expected_date_of_sale).format("DD/MM/YYYY"),
    },
    {
      title: "Pack Size",
      dataIndex: "pack_size",
      key: "pack_size",
      align: "center",
      sorter: (a, b) => a["pack_size"].localeCompare(b["pack_size"]),
      width: 120,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      sorter: (a, b) => a["quantity"].localeCompare(b["quantity"]),
      width: 120,
    },
    ...(isUserPartner || isUserStaff
      ? [
          {
            title: "Buyer Name and Address",
            dataIndex: ["buyer_name_and_address"],
            key: "buyer_name_and_address",
            align: "center",
            sorter: (a, b) => a["buyer_name_and_address"].localeCompare(b["buyer_name_and_address"]),
            width: 200,
          },
        ]
      : []),
    ...(isUserPartner || isUserStaff
      ? [
          {
            title: "End Customer and Address",
            dataIndex: ["end_customer_and_address"],
            key: "end_customer_and_address",
            align: "center",
            sorter: (a, b) => a["end_customer_and_address"].localeCompare(b["end_customer_and_address"]),
            width: 200,
          },
        ]
      : []),
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 210,
      filters: royaltyApprovalStatus.map((option) => ({
        text: option,
        value: option,
      })),
      defaultFilteredValue: queryParams.get("status")? queryParams.getAll("status") : [],
      onFilter: (value, record) =>
        record.status.toString().toLowerCase().includes(value?.toLowerCase()),
      render: (text, record) => (
        <Tooltip title={text !== "PENDING" ? record.comment : null}>
          <span
            style={{
              color:
                text === "APPROVED"
                  ? "green"
                  : text === "REJECTED"
                  ? "red"
                  : text === "NEEDS CLARIFICATION" ||
                    text === "REVISIONS REQUIRED"
                  ? "orange"
                  : "black",
            }}
          >
            {text}
          </span>
          {text !== "PENDING" && text !== "NEEDS CLARIFICATION" && (
            <InfoCircleTwoTone className="ml-10" twoToneColor="#00AEEF" />
          )}
          {record.clarification.length !== 0 && (
            <Tooltip
              onClick={() => {
                handleClarificationClick(record.clarification);
                setRequestStatus(text == "NEEDS CLARIFICATION" ? true : false);
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  paddingLeft: "5px",
                }}
              >
                <MessageTwoTone twoToneColor="#00AEEF" className="font-20" />
              </span>
            </Tooltip>
          )}
        </Tooltip>
      ),
    },
    {
      title: "Documents",
      dataIndex: "file",
      key: "file",
      align: "center",
      width: 140,
      render: (files) => (
        <Tooltip style={{ textAllign: "left" }}>
          {files.map((file, index) => (
            <>
              <a
                key={file.po_id}
                href={file.po_document}
                target="_blank"
                rel="noopener noreferrer"
              >
                PO {index + 1}
              </a>
              <br />
            </>
          ))}
        </Tooltip>
      ),
    },

    {
      title: "Request ID",
      dataIndex: "mapping_id",
      key: "mapping_id",
      align: "center",
      width: 250,
      filters: [{
          text: "Sales Completed",
          value: "limegreen",
        },
        {
          text: "Partially Sales Completed",
          value: "orange",
        },
        ,
        {
          text: "Pending Sales",
          value: "black",
      }],
      defaultFilteredValue: queryParams.get("mapping_id")? queryParams.getAll("mapping_id") : [],
      onFilter: (value, record) => (
      record.sales_data.length > 0 && record.sales_data.reduce((total,item) => total + parseInt(item.quantity), 0) === record.quantity && value === 'limegreen' ||
      record.sales_data.length > 0 && record.quantity && record.sales_data.reduce((total,item) => total + parseInt(item.quantity), 0) < record.quantity  &&
      record.sales_data.reduce((total,item) => total + parseInt(item.quantity), 0) !== record.quantity && value === 'orange' ||
      record.sales_data && record.sales_data.length === 0 && value === 'black'
      ),
      render: (text, record, index) => (

        <Tooltip
          onClick={() =>
            handleRequestClick(record.request_id, record.mapping_id)
          }
        >
          <span
            style={{
              color:
              record.sales_data.length > 0 && record.sales_data.reduce((total,item) => total + parseInt(item.quantity), 0) === record.quantity
                  ? "limegreen"
                : record.sales_data.length > 0 && record.quantity && record.sales_data.reduce((total,item) => total + parseInt(item.quantity), 0) < record.quantity
                  ? "orange"
                : "black"
            }}
          >
            <b>{text}</b>
          </span>
        </Tooltip>
      ),
    },

    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 140,
      render: (item) => (
        <div style={{ display: "inline-flex" }}>
          {isUserAdmin ? (
            <>
              <Tooltip title="View request">
                <EyeTwoTone
                  className="font-20"
                  twoToneColor="#00AEEF"
                  onClick={() => {
                    navigateToViewContent(item);
                  }}
                  id={"view-" + item.request_id}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                type="link"
                className="margin-0"
                onClick={() => {
                  navigateToViewContent(item);
                }}
                id={"view-" + item.request_id}
              >
                <Tooltip title="View request">
                  <EyeTwoTone className="font-20" twoToneColor="#00AEEF" />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className="margin-0"
                id={"upload-" + item.request_id}
                disabled={
                  item.status === "APPROVED" || item.status === "PENDING"
                }
                onClick={() => {
                  navigateToContent(item);
                }}
              >
                <Tooltip title="Edit">
                  <EditOutlined twoToneColor="#00AEEF" className="font-20" />
                </Tooltip>
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col span={24}>
          <div
            className="gx-flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <h1 className="title gx-mb-4">
              <IntlMessages id="sidebar.royalty-sales-approval-request" />
            </h1>
            <div>
              {isUserPartner && (
                <Button
                  type="primary"
                  id="raise-request"
                  onClick={navigateToAddSalesData}
                >
                  <CopyOutlined className="font-16" />
                  Raise Request
                </Button>
              )}
            <Tooltip title={<IntlMessages id='po-request.download' />}>
              <Button id='po-request.download' className='mr-0' onClick={handleDownloadClick} >
                  <DownloadOutlined className='font-20' />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Card className="mb-0">
            <Table
              className="gx-table-responsive mpp-list-table"
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              bordered
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title={`Request ID: ${selectedRequest}`}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={false}
        width="50%" // Set the width as a percentage or any valid CSS value
        centered // Center the modal on the page
      >
        {!isRoyaltySalesMappedLoaded ? (
          <Spin tip="Loading..." />
        ) : (
          <div
            className="ag-theme-balham ag-scroll-bar-fix"
            style={{ height: "500px", width: "100%", margin: "auto" }}
          >
            <AgGridReact
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              rowData={salesmappeddata}
              defaultColDef={{
                filter: true,
                sortable: true,
                width: 300,
                resizable: true,
              }}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
            />
          </div>
        )}
      </Modal>
      <Modal
        title="Clarification Chat"
        visible={isChatModalVisible}
        onCancel={() => setIsChatModalVisible(false)}
        footer={null}
      >
        <div style={{ maxHeight: 400, overflowY: "auto" }}>
          <List
            itemLayout="horizontal"
            dataSource={chatConversation}
            renderItem={(message, index) => (
              <List.Item
                className={classNames("chat-message", {
                  "chat-message-user": !message.is_partner,
                  "chat-message-partner": message.is_partner,
                })}
              >
                <List.Item.Meta
                  description={
                    <div
                      style={{
                        background: message.is_partner ? "#f6f6f6" : "#e6f7ff",
                      }}
                      className="chat-message-content"
                    >
                      {message.message}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
        {requestSatus && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TextArea
              rows={3}
              value={messageInput}
              onChange={handleMessageChange}
              placeholder="Type your message..."
              autoFocus
              style={{ flex: 1, marginRight: 10 }}
            />
            <Button
              type="primary"
              onClick={() => {
                handleSendMessage(chatConversation[0].request_id);
              }}
            >
              Send
            </Button>
          </div>
        )}
      </Modal>
      {/* <ClarificationChat
        isUserPartner={isUserPartner}
        isChatModalVisible={isChatModalVisible}
        setIsChatModalVisible={setIsChatModalVisible}
        setChatConversation={setChatConversation}
        chatConversation={chatConversation}
      /> */}
    </div>
  );
};

export default PartnerRoyaltySalesApproval;
