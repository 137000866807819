import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Auth } from "../helpers/auth";
import { roleConstants } from "../constants";

import AdminDashboard from "./pages/dashboard/adminDashboard";
import PartnerDashboard from "./pages/partner-dashboard";
import PartnerListNew from "./pages/partner-list-new";
import PartnerPerformance from "./pages/partner-peformance";
import ProductList from "./pages/product-list";
import DevelopmentTimeline from "./pages/development-timeline";
import FilingPlans from "./pages/filing-plans";
import SalesReport from "./pages/sales-report";
import ChangePassword from "./pages/change-password";
import AddPartner from "./pages/add-partner";
import AddProduct from "./pages/add-product";
import PDTReport from "./pages/report-pdt";
import FilingPlansReport from "./pages/report-filing-plans";
import ConsolidatedSalesReport from "./pages/report-sales-report";
import UserProfilePage from "./pages/profile";
import AdminSettings from "./pages/admin-settings";
import PartnerCompliance from "./pages/partner-compliance";
import MOM from "./pages/MOM";
import ComplianceTemplate from "./pages/compliance-template";
import AddComplianceTemplate from "./pages/add-compliance-template";
import AssignComplianceTemplate from "./pages/assign-compliance-template";
import PartnerComplianceLetter from "./pages/partner-compliance-letter";
import InteractiveMap from "./pages/interactive-map";
import Chats from "./pages/chats";
import PartnerRoyaltySalesApproval from "./pages/royalty-sales-approvals";
import RaiseApprovalRequest from "./pages/raise-approval-request";
import PartnerInvoice from "./pages/royalty-invoice-tracking";
import ViewRaisedRequest from "./pages/view-raised-request";
import AddInvoiceData from "./pages/add-invoice-data";
import ViewInvoiceInvoiceTracking from "./pages/view-invoice";
import AddPaymentProof from "./pages/add-payment-proof";
import AddTaxData from "./pages/add-tax-data";
import ViewTaxInvoiceTracking from "./pages/view-tax-data";

const AdminDashboardPage = Auth(AdminDashboard, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.PARTNER,
]);
const ChatsPage = Auth(Chats, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.PARTNER,
]);
const PartnerDashboardPage = Auth(PartnerDashboard, [roleConstants.PARTNER]);
const ChangePasswordPage = Auth(ChangePassword, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.PARTNER,
  roleConstants.LEGAL,
  roleConstants.LICENSOR,
]);
const AdminProfilePage = Auth(UserProfilePage, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.LEGAL,
  roleConstants.LICENSOR,
]);
const PartnerProfilePage = Auth(UserProfilePage, [
  roleConstants.PARTNER
]);
const PartnerListNewPage = Auth(PartnerListNew, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const AddPartnerPage = Auth(AddPartner, [roleConstants.ADMIN]);
const PartnerPerformancePage = Auth(PartnerPerformance, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const ProductListPage = Auth(ProductList, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const AddProductPage = Auth(AddProduct, [roleConstants.ADMIN]);
const DevelopmentTimelinePage = Auth(DevelopmentTimeline, [
  roleConstants.PARTNER,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const FilingPlansPage = Auth(FilingPlans, [
  roleConstants.PARTNER,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const SalesReportPage = Auth(SalesReport, [
  roleConstants.PARTNER,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const PDTReportPage = Auth(PDTReport, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const FilingPlansReportPage = Auth(FilingPlansReport, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const ConsolidatedSalesReportPage = Auth(ConsolidatedSalesReport, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const AdminSettingsPage = Auth(AdminSettings, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const PartnerCompliancePage = Auth(PartnerCompliance, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.LEGAL,
]);
const PartnerRoyaltySalesApprovalPage = Auth(PartnerRoyaltySalesApproval, [
  roleConstants.PARTNER,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const ViewRaisedRequestPage = Auth(ViewRaisedRequest, [
  roleConstants.ADMIN,
  roleConstants.PARTNER,
  roleConstants.STAFF,
]);
const RaiseApprovalRequestPage = Auth(RaiseApprovalRequest, [
  roleConstants.PARTNER,
]);
const MOMPage = Auth(MOM, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.LEGAL,
]);
const ComplianceTemplatePage = Auth(ComplianceTemplate, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.LEGAL,
]);
const AddComplianceTemplatePage = Auth(AddComplianceTemplate, [
  roleConstants.ADMIN,
  roleConstants.LEGAL,
]);
const AssignComplianceTemplatePage = Auth(AssignComplianceTemplate, [
  roleConstants.ADMIN,
  roleConstants.LEGAL,
]);
const PartnerComplianceLetterPage = Auth(PartnerComplianceLetter, [
  roleConstants.PARTNER,
  roleConstants.LEGAL,
]);
const InteractiveMapPage = Auth(InteractiveMap, [
  roleConstants.ADMIN,
  roleConstants.STAFF,
  roleConstants.LEGAL,
]);
const PartnerInvoicePage = Auth(PartnerInvoice, [
  roleConstants.ADMIN,
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
  roleConstants.STAFF,
]);
const AddInvoiceDataPage = Auth(AddInvoiceData, [
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
]);
const AddTaxDataPage = Auth(AddTaxData, [
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
]);
const ViewInvoiceInvoiceTrackingPage = Auth(ViewInvoiceInvoiceTracking, [
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const ViewTaxInvoiceTrackingPage = Auth(ViewTaxInvoiceTracking, [
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
  roleConstants.ADMIN,
  roleConstants.STAFF,
]);
const AddPaymentProofPage = Auth(AddPaymentProof, [
  roleConstants.PARTNER,
  roleConstants.LICENSOR,
  roleConstants.ADMIN,
]);

const NotFoundRedirect = () => <Redirect to="/error-404" />;

class App extends Component {
  render() {
    return (
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route exact path="/change-password" component={ChangePasswordPage} />
          <Route exact path="/admin/dashboard" component={AdminDashboardPage} />
          <Route
            exact
            path="/admin/partner-list"
            component={PartnerListNewPage}
          />
          <Route exact path="/admin/chats" component={ChatsPage} />
          <Route
            exact
            path="/admin/edit-partner/:id"
            component={AddPartnerPage}
          />
          <Route exact path="/admin/add-partner" component={AddPartnerPage} />
          <Route
            exact
            path="/admin/partner-performance"
            component={PartnerPerformancePage}
          />
          <Route exact path="/admin/product-list" component={ProductListPage} />
          <Route exact path="/admin/add-product" component={AddProductPage} />
          <Route
            exact
            path="/admin/edit-product/:product_id"
            component={AddProductPage}
          />
          <Route
            exact
            path="/admin/development-timeline/:id"
            component={DevelopmentTimelinePage}
          />
          <Route
            exact
            path="/admin/filing-plans/:id"
            component={FilingPlansPage}
          />
          <Route
            exact
            path="/admin/sales-report/:id"
            component={SalesReportPage}
          />
          <Route
            exact
            path="/admin/report/development-timeline"
            component={PDTReportPage}
          />
          <Route
            exact
            path="/admin/report/filing-plans"
            component={FilingPlansReportPage}
          />
          <Route
            exact
            path="/admin/report/sales-report"
            component={ConsolidatedSalesReportPage}
          />
          <Route exact path="/admin/profile" component={AdminProfilePage} />
          <Route exact path="/admin/settings" component={AdminSettingsPage} />
          <Route
            exact
            path="/admin/partner-compliance"
            component={PartnerCompliancePage}
          />
          <Route
            exact
            path="/partner/royalty-sales-approvals"
            component={PartnerRoyaltySalesApprovalPage}
          />
          <Route
            exact
            path="/partner/partner-invoice"
            component={PartnerInvoicePage}
          />
          <Route
            exact
            path="/partner/royalty-sales-approvals/view/:id"
            component={ViewRaisedRequestPage}
          />
          <Route
            exact
            path="/partner/partner-invoice/view/:id"
            component={ViewInvoiceInvoiceTrackingPage}
          />
          <Route
            exact
            path="/partner/partner-invoice/add-payment-proof/:id"
            component={AddPaymentProofPage}
          />
          <Route exact path="/admin/MOM" component={MOMPage} />
          <Route
            exact
            path="/admin/compliance-template"
            component={ComplianceTemplatePage}
          />
          <Route
            exact
            path="/admin/add-compliance-template"
            component={AddComplianceTemplatePage}
          />
          <Route
            exact
            path="/partner/royalty-sales-approvals/raise-request"
            component={RaiseApprovalRequestPage}
          />
          <Route
            exact
            path="/partner/partner-invoice/raise-request"
            component={AddInvoiceDataPage}
          />
           <Route
            exact
            path="/partner/partner-tax-invoice/raise-request"
            component={AddTaxDataPage}
          />
           <Route
            exact
            path="/partner/partner-tax-invoice/edit-request/:id"
            component={AddTaxDataPage}
          />
           <Route
            exact
            path="/partner/tax-invoice/view/:id"
            component={ViewTaxInvoiceTrackingPage}
          />
          <Route
            exact
            path="/partner/partner-invoice/edit-request/:id"
            component={AddInvoiceDataPage}
          />
          <Route
            exact
            path="/partner/royalty-sales-approvals/edit-request/:id"
            component={RaiseApprovalRequestPage}
          />
          <Route
            exact
            path="/partner/partner-invoice/add-payment-proof/edit-request/:id"
            component={AddPaymentProofPage}
          />
          <Route
            exact
            path="/admin/compliance-template/assign"
            component={AssignComplianceTemplatePage}
          />
          <Route
            exact
            path="/admin/MOM/assign"
            component={AssignComplianceTemplatePage}
          />
          <Route
            exact
            path="/admin/edit-compliance-template/:template_id"
            component={AddComplianceTemplatePage}
          />
          <Route
            exact
            path="/partner/dashboard"
            component={PartnerDashboardPage}
          />
          <Route
            exact
            path="/partner/development-timeline"
            component={DevelopmentTimelinePage}
          />
          <Route
            exact
            path="/partner/filing-plans"
            component={FilingPlansPage}
          />
          <Route
            exact
            path="/partner/sales-report"
            component={SalesReportPage}
          />
          <Route exact path="/partner/profile" component={PartnerProfilePage} />
          <Route
            exact
            path="/partner/compliance-letter"
            component={PartnerComplianceLetter}
          />
          <Route
            exact
            path="/admin/interactive-map"
            component={InteractiveMapPage}
          />

          <Route component={NotFoundRedirect} />
        </Switch>
      </div>
    );
  }
}

export default App;
