import React, { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Modal,
  Card,
  Table,
  Row,
  Col,
  Button,
  Tooltip,
  Input,
  Form,
  Spin,
  Tabs,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerInvoiceData,
  getPartnerTaxInvoiceData,
  downloadInvoiceDocument,
} from "../../../appRedux/actions";
import {
  EditOutlined,
  UploadOutlined,
  EyeTwoTone,
  InfoCircleTwoTone,
  DownloadOutlined
} from "@ant-design/icons";
import { getRole, getUserInfo, getIsApprover } from "../../../helpers";
import moment from "moment";
import { royaltyInvoiceStatus } from "../../../constants";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
const { TextArea } = Input;

const { TabPane } = Tabs;

const PartnerInvoice = (props) => {
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserLicensor, setIsUserLicensor] = useState(false);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const [isUserStaff, setIsUserStaff] = useState(false);
  const [productFilterOptions, setProductFilterOptions] = useState([]);
  const [countryFilterOptions, setCountryFilterOptions] = useState([]);
  const [partnerFilterOptions, setPartnerFilterOptions] = useState([]);
  const [isAdminApprover, setIsAdminApprover] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isModalVisible,  ] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requestObj, setRequestObj] = useState({ page_number: 1, page_size: 10000 });
  const [licensorFilterOptions, setLicensorFilterOptions] = useState([]);

  const {
    invoiceRequestdata,
    taxRequestdata,
    isTaxDataLoaded,
    isInvoiceRequestDeleted,
    isRoyaltyInvoiceLoaded,
  } = useSelector(({ partnerInvoice }) => partnerInvoice);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [form] = Form.useForm();

  const navigateToAddSalesData = () => {
    const { history } = props;
    history.push("/partner/partner-invoice/raise-request");
  };

  const navigateToAddTaxData = () => {
    const { history } = props;
    history.push("/partner/partner-tax-invoice/raise-request");
  };

  useEffect(() => {
    setUserDetails(getUserInfo());
    setIsAdminApprover(getIsApprover());
    setTaxData(dispatch(getPartnerTaxInvoiceData()));
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserLicensor(() => {
      return getRole() === "LICENSOR";
    });
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });
    setIsUserStaff(() => {
      return getRole() === "STAFF";
    });
    dispatch(getPartnerInvoiceData());
  }, []);

  useEffect(() => {
    setData(invoiceRequestdata);
  }, [isInvoiceRequestDeleted, isRoyaltyInvoiceLoaded]);
  useEffect(() => {
    setTaxData(taxRequestdata);
  }, [isTaxDataLoaded]);

  const invoiceDataRevise = () => {
    if (isUserAdmin) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].partner_status == "INVALID" && data[index].licensor_status == "UNDER PROCESS") {
          data[index].licensor_status = "INVALID"
        }
        if (data[index].partner_status == "NEEDS REVISION" && data[index].licensor_status == "UNDER PROCESS") {
          data[index].licensor_status = "NEEDS REVISION"
        }
      }
    }
    return data
  }

  const navigateToContent = (item) => {
    props.history.push(
      `/partner/partner-invoice/edit-request/${item.tracking_id}`
    );
  };
  const navigateToPaymentContent = (item) => {
    props.history.push(
      `/partner/partner-invoice/add-payment-proof/edit-request/${item.tracking_id}`
    );
  };
  const navigateToTaxForm = (item) => {
    props.history.push(
      `/partner/partner-tax-invoice/edit-request/${item.tax_id}`
    );
  };

  const navigateToViewContent = (item) => {
    props.history.push(`/partner/partner-invoice/view/${item.tracking_id}`);
  };
  const navigateToTaxViewContent = (item) => {
    props.history.push(`/partner/tax-invoice/view/${item.tax_id}`);
  };

  useEffect(() => {
    const uniqueProductNames = [
      ...new Set(data.map(({ product_id }) => product_id?.product_name)),
    ];
    const uniqueCountryNames = [
      ...new Set(data.map(({ country_id }) => country_id?.country_name)),
    ];
    const uniquePartnerNames = [
      ...new Set(data.map(({ partner_id }) => partner_id?.company_name)),
    ];
    const uniqueLicensorNames = [
      ...new Set(data.map(({ licensor_id }) => licensor_id?.first_name)),
    ];
    setProductFilterOptions(uniqueProductNames);
    setCountryFilterOptions(uniqueCountryNames);
    setPartnerFilterOptions(uniquePartnerNames);
    setLicensorFilterOptions(uniqueLicensorNames);
  }, [data]);

  const showCommentsForm = () => {
    return (
      <Form form={form} layout="vertical" className="mt-20">
        <div className="mb-10">
          <span>Add comments for partner:</span>
        </div>
        <Form.Item
          name="comment"
          className="mb-0"
          rules={[
            {
              required: isUserAdmin,
              message: isUserAdmin
                ? "Enter comments for partner"
                : "Enter comments for admin",
            },
          ]}
        >
          <TextArea placeholder="Enter comments here" />
        </Form.Item>
      </Form>
    );
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 150,
    },
    {
      title: "Quarter",
      dataIndex: "quarter",
      key: "quarter",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Tooltip>
          {record.quarter} - {record.year}
        </Tooltip>
      ),
    },
    ...(isUserAdmin || isUserLicensor || isUserStaff
      ? [
          {
            title: "Partner Name",
            dataIndex: ["partner_id", "company_name"],
            key: "partner_id",
            align: "center",
            width: 200,
            filters: partnerFilterOptions.map((option) => ({
              text: option,
              value: option,
            })),
            onFilter: (value, record) =>
              record["partner_id"]["company_name"]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
          },
        ]
      : []),

      ...(isUserAdmin || isUserPartner || isUserStaff
        ? [
            {
              title: "Licensor Name",
              dataIndex: ["licensor_id", "first_name"],
              key: "licensor_id",
              align: "center",
              width: 180,
              filters: licensorFilterOptions.map((option) => ({
                text: option,
                value: option,
              })),
              onFilter: (value, record) =>
                record["licensor_id"]["first_name"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
            },
          ]
        : []),

    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
      align: "center",
      width: 140,
      sorter: (a, b) => a["issue_date"].localeCompare(b["issue_date"]),
      render: (text, record) => moment(record.issue_date).format("DD/MM/YYYY"),
    },
    {
      title: "Amount(USD)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 140,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      align: "center",
      width: 140,
      sorter: (a, b) => a["payment_date"].localeCompare(b["payment_date"]),
      render: (text, record) => {
        if (record.payment_date) {
          return moment(record.payment_date).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Invoice Due Date",
      dataIndex: "invoice_due_date",
      key: "invoice_due_date",
      align: "center",
      width: 140,
      sorter: (a, b) =>
        a["invoice_due_date"].localeCompare(b["invoice_due_date"]),
      render: (text, record) => {
        if (record.invoice_due_date) {
          return moment(record.invoice_due_date).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      title: "Remaining Days",
      dataIndex: "remaining_days",
      key: "remaining_days",
      align: "center",
      width: 140,
      render: (text, record) => {
        const invoiceDueDate = moment(new Date(record.invoice_due_date).toDateString());
        const todayDate = moment(new Date().toDateString());
        // As per the requirement remaining days should be due date - current date
        if (invoiceDueDate.isValid()) {
          var diffDays = invoiceDueDate.diff(todayDate, "days")
          return diffDays;
        } else {
          return "-";
        }
      },
    },

    ...(isUserAdmin || isUserLicensor || isUserStaff
      ? [
          {
            title: "Status",
            dataIndex: "licensor_status",
            key: "licensor_status",
            align: "center",
            width: 210,
            filters: royaltyInvoiceStatus.map((option) => ({
              text: option,
              value: option,
            })),
            onFilter: (value, record) =>
              record.licensor_status
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            render: (text, record) => (
              <Tooltip
                title={text !== "PENDING" ? record.licensor_comment : null}
              >
                <span
                  style={{
                    color:
                      text === "PAID" || text === "APPROVED"
                        ? "green"
                        : text === "INVALID" || text === "NEEDS REVISION"
                        ? "red"
                        : "black",
                  }}
                >
                  {text}
                  {text !== "PENDING" && (
                    <InfoCircleTwoTone
                      className="ml-10"
                      twoToneColor="#00AEEF"
                    />
                  )}
                </span>
              </Tooltip>
            ),
          },
        ]
      : []),
    ...(isUserPartner
      ? [
          {
            title: "Status",
            dataIndex: "partner_status",
            key: "partner_status",
            align: "center",
            width: 210,
            render: (text, record) => (
              <Tooltip
                title={text !== "PENDING" ? record.partner_comment : null}
              >
                <span
                  style={{
                    color:
                      text === "PAID" || text === "APPROVED"
                        ? "green"
                        : text === "INVALID" || text === "REJECTED"
                        ? "red"
                        : "black",
                  }}
                >
                  {text}
                  {text !== "PENDING" && (
                    <InfoCircleTwoTone
                      className="ml-10"
                      twoToneColor="#00AEEF"
                    />
                  )}
                </span>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "Invoice",
      dataIndex: "invoice_file",
      key: "invoice_file",
      align: "center",
      width: 140,
      render: (files) => (
        <Tooltip style={{ textAllign: "left" }}>
          {files?.map((file, index) => (
            <>
              <a
                key={file.id}
                href={file.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                INVOICE {index + 1}
              </a>
              <br />
            </>
          ))}
        </Tooltip>
      ),
    },
    {
      title: "Payment Proof",
      dataIndex: "payment_file",
      key: "payment_file",
      align: "center",
      width: 140,
      render: (files) => (
        <Tooltip style={{ textAllign: "left" }}>
          {files?.map((file, index) => (
            <>
              <a
                key={file.id}
                href={file.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                PAYMENT PROOF {index + 1}
              </a>
              <br />
            </>
          ))}
        </Tooltip>
      ),
    },

    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 140,
      render: (item) => (
        <div style={{ display: "inline-flex" }}>
          {isUserAdmin ? (
            <>
              <Tooltip title="View request">
                <EyeTwoTone
                  className="font-20"
                  twoToneColor="#00AEEF"
                  onClick={() => {
                    navigateToViewContent(item);
                  }}
                  id={"view-" + item.tracking_id}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                type="link"
                className="margin-0"
                onClick={() => {
                  navigateToViewContent(item);
                }}
                id={"view-" + item.tracking_id}
              >
                <Tooltip title="View request">
                  <EyeTwoTone className="font-20" twoToneColor="#00AEEF" />
                </Tooltip>
              </Button>
              {isUserLicensor && (
                <Button
                  type="link"
                  className="margin-0"
                  id={"upload-" + item.tracking_id}
                  disabled={
                    item.licensor_status === "PAID" ||
                    item.licensor_status === "PENDING" ||
                    item.licensor_status === "APPROVED"
                  }
                  onClick={() => {
                    navigateToContent(item);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditOutlined twoToneColor="#00AEEF" className="font-20" />
                  </Tooltip>
                </Button>
              )}
              {isUserPartner && (
                <Button
                  type="link"
                  className="margin-0"
                  id={"upload-" + item.tracking_id}
                  disabled={
                    item.partner_status === "PAID" ||
                    item.partner_status === "PENDING" ||
                    item.partner_status === "UNDER PROCESS"
                  }
                  onClick={() => {
                    navigateToPaymentContent(item);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditOutlined twoToneColor="#00AEEF" className="font-20" />
                  </Tooltip>
                </Button>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const taxColumns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 150,
    },
    ...(isUserAdmin || isUserPartner
      ? [
          {
            title: "Licensor Name",
            dataIndex: ["licensor_id", "first_name"],
            key: "licensor_id",
            align: "center",
            width: 180,
          },
        ]
      : []),
      ...(isUserAdmin || isUserLicensor || isUserStaff
        ? [
            {
              title: "Partner Name",
              dataIndex: ["partner_id", "company_name"],
              key: "partner_id",
              align: "center",
              width: 200,
              filters: partnerFilterOptions.map((option) => ({
                text: option,
                value: option,
              })),
              onFilter: (value, record) =>
                record["partner_id"]["company_name"]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
            },
          ]
        : []),
    // {
    //   title: "Partner Name",
    //   dataIndex: ["partner_id", "company_name"],
    //   key: "partner_id",
    //   align: "center",
    //   width: 200,
    //   filters: partnerFilterOptions.map((option) => ({
    //     text: option,
    //     value: option,
    //   })),
    //   onFilter: (value, record) =>
    //     record["partner_id"]["company_name"]
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()),
    // },

    ...(isUserAdmin || isUserPartner || isUserStaff
      ? [
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 210,
          filters: royaltyInvoiceStatus.map((option) => ({
            text: option,
            value: option,
          })),
          onFilter: (value, record) =>
            record.licensor_status
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          render: (text, record) => (
            <Tooltip
              title={text !== "PENDING" ? record.licensor_comment : null}
            >
              <span
                style={{
                  color:
                    text === "PAID" || text === "APPROVED"
                      ? "green"
                      : text === "INVALID" || text === "REJECTED" || text === "NEEDS REVISION"
                      ? "red"
                      : "black",
                }}
              >
                {text}
                {text !== "PENDING" && (
                  <InfoCircleTwoTone
                    className="ml-10"
                    twoToneColor="#00AEEF"
                  />
                )}
              </span>
            </Tooltip>
          ),
        },
        ]
      : [
        {
          title: "Status",
          dataIndex: "licensor_status",
          key: "status",
          align: "center",
          width: 210,
          filters: royaltyInvoiceStatus.map((option) => ({
            text: option,
            value: option,
          })),
          onFilter: (value, record) =>
            record.licensor_status
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          render: (text, record) => (
            <Tooltip
              title={text !== "PENDING" ? record.licensor_comment : null}
            >
              <span
                style={{
                  color:
                    text === "PAID" || text === "APPROVED"
                      ? "green"
                      : text === "INVALID" || text === "REJECTED" || text === "NEEDS REVISION"
                      ? "red"
                      : "black",
                }}
              >
                {text}
                {text !== "PENDING" && (
                  <InfoCircleTwoTone
                    className="ml-10"
                    twoToneColor="#00AEEF"
                  />
                )}
              </span>
            </Tooltip>
          ),
        }
      ]),

    {
      title: "Documents",
      dataIndex: "invoice_file",
      key: "invoice_file",
      align: "center",
      width: 140,
      render: (files) => (
        <Tooltip style={{ textAllign: "left" }}>
          {files?.map((file, index) => (
            <>
              <a
                key={file.id}
                href={file.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                DOC {index + 1}
              </a>
              <br />
            </>
          ))}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 140,
      render: (item) => (
        <div style={{ display: "inline-flex" }}>
          {isUserAdmin || isUserPartner ? (
            <>
              <Tooltip title="View request">
                <EyeTwoTone
                  className="font-20"
                  twoToneColor="#00AEEF"
                  onClick={() => {
                    navigateToTaxViewContent(item);
                  }}
                  id={"view-" + item.tax_id}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                type="link"
                className="margin-0"
                onClick={() => {
                  navigateToTaxViewContent(item);
                }}
                id={"view-" + item.tax_id}
              >
                <Tooltip title="View request">
                  <EyeTwoTone className="font-20" twoToneColor="#00AEEF" />
                </Tooltip>
              </Button>
            </>
          )}
          {isUserLicensor && (
                <Button
                  type="link"
                  className="margin-0"
                  id={"upload-" + item.tracking_id}
                  disabled={
                    item.status === "PENDING" ||
                    item.status === "APPROVED"
                  }
                  onClick={() => {
                    navigateToTaxForm(item);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditOutlined twoToneColor="#00AEEF" className="font-20" />
                  </Tooltip>
                </Button>
              )}
              {isUserPartner && (
                <Button
                  type="link"
                  className="margin-0"
                  id={"upload-" + item.tracking_id}
                  disabled={
                    item.status === "PENDING"
                  }
                  onClick={() => {
                    navigateToTaxForm(item);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditOutlined twoToneColor="#00AEEF" className="font-20" />
                  </Tooltip>
                </Button>
              )}
        </div>
      ),
    },
  ]

  const displayGridCta = () => {
      return (
          <div>
            {isUserLicensor && (
              <Button
                type="primary"
                id="raise-request"
                onClick={navigateToAddTaxData}
              >
                <UploadOutlined />
                Add Tax Document
              </Button>
            )}

            {isUserLicensor && (
              <Button
                type="primary"
                id="raise-request"
                onClick={navigateToAddSalesData}
              >
                <UploadOutlined />
                Add Invoice
              </Button>
            )}
            <Tooltip title={<IntlMessages id='invoice.download' />}>
                  <Button id='invoice.download' className='mr-0' onClick={() => dispatch(downloadInvoiceDocument('royalty-partner-invoice.csv', requestObj))} >
                      <DownloadOutlined className='font-20' />
                  </Button>
              </Tooltip>
          </div>
      )
}

  return (
    <div>
        <Col span={24}>
          <div className="gx-flex-row" style={{ justifyContent: "space-between" }}>
            <Tabs
              tabPosition='top'
              type='card'
              className='sales-report-tabs'
              tabBarExtraContent={displayGridCta()}
            >
              <TabPane tab="Partner Invoice" key="1" >
                <Row>
                <Col span={24}>
                  <Card className="mb-0">
                    <Table
                      className="gx-table-responsive mpp-list-table"
                      columns={columns}
                      dataSource={invoiceDataRevise()}
                      bordered
                    />
                  </Card>
                </Col>
                </Row>
              </TabPane>
              <TabPane tab="Tax Documents" key="2" >
                <Row>
                <Col span={24}>
                  <Card className="mb-0">
                    <Table
                      className="gx-table-responsive mpp-list-table"
                      columns={taxColumns}
                      dataSource={taxData}
                      bordered
                    />
                  </Card>
                </Col>
                </Row>
              </TabPane>
            </Tabs>
        </div>
        </Col>
    </div>
  );
};

export default PartnerInvoice;
